<template>
  <div class="option-firm">
    <div class="option-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`@/assets/image/work.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">AI求职</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`@/assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <div class="option-main">
      <div class="main-button" @click="gotoSchool">
        <div class="top">
          <el-image
            class="img"
            :src="require(`@/assets/image/option_left.png`)"
            fit="contain"
          ></el-image>
        </div>
        <div class="button">
          <span>我要求职</span>
        </div>
      </div>
      <div class="main-button" @click="gotoFirm">
        <div class="top">
          <el-image
            class="img"
            :src="require(`@/assets/image/option_right.png`)"
            fit="contain"
          ></el-image>
        </div>
        <div class="button right">
          <span>我要招聘</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    gotoFirm() {
      this.$router.push("/workFirm");
    },
    gotoSchool() {
      this.$router.push("/workSchool");
    }
  }
}
</script>

<style lang="less" scoped>
.option-firm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .option-main {
    height: 92%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .main-button {
      height: 360px;
      width: 360px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .top {
        height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          height: 180px;
          width: 180px;
        }
      }
      .button {
        height: 90px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34px;
        font-weight: bold;
        color: #fff;
        background-color: #fbce77;
      }
      .right {
        background-color: #5fb7ff;
      }
    }
  }
  .option-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>